import { useRouter } from "next/router"
import { useEffect } from "react";
import { db } from '@/firebase'
import { ref } from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';
import { getSalonId, getSalonBranchId } from '@/utils'
import { RaitingRecordModel } from '@/models'
import { InvoiceService, RaitingService } from '@/services'
import { useStore, setInvoice, setRaitingSetting } from '@/store'
import { setInvoiceId } from '@/utils'
import { useQuery } from "@tanstack/react-query";


export type raitingValuesInDB = RaitingRecordModel | null | '' | undefined

export const useRating = () => {

    const [state, dispatch] = useStore();
    const router = useRouter();
    const [value, loading, error] = useObjectVal<raitingValuesInDB>(ref(db, `salons/${getSalonId()}/${getSalonBranchId()}/invoice-staff-rating`));

    const { data: raitingSetting } = useQuery(['raitingSetting'], () =>
        RaitingService.getRaitingSettingBySalonId(getSalonId()!)
    )

    useEffect(() => {
        raitingSetting && dispatch(setRaitingSetting(raitingSetting))
    }, [dispatch, raitingSetting])


    const getInvoice = async (invoiceId: string) => {
        const res = await InvoiceService.getInvoiceById(invoiceId);
        dispatch(setInvoice(res.data))
        if (res.data.customer) {
            router.asPath === '/' && router?.push('/xac-nhan-hoa-don')
        } else {
            router.asPath === '/' && router?.push('/customer/so-dien-thoai')
        }
    }

    useEffect(() => {
        if (value) {
            if (value.invoiceId) {
                setInvoiceId(value.invoiceId)
                getInvoice(value.invoiceId)
                return;
            }
            if (value.invoiceId === '') {
                router.asPath !== '/' && router?.push('/')
            }
        }
    }, [value])

}